module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-chakra-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Andris Borbas's personal site","description":"Andris Borbas's personal site.","siteUrl":"https://andrisborbas.com","display":"minimal-ui","theme_color":"#3c4b67","background_color":"#1A202C","icon":"src/assets/favicon.png","lang":"en-US","start_url":"/","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"37161ce361b773e21b8ef44d82accbfa"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
